<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata" :inline="true" :model="formdata.saveJson" class="demo-form-inline form_row" size="small" label-width="200px">
                <el-form-item label="发布时间" prop="REPORT_DATE">
                    <el-date-picker v-model="formdata.saveJson.REPORT_DATE" :disabled="!isEdit" value-format="yyyy-mm-dd" placeholder="请选择发布时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="二氧化硫 1小时平均浓度" prop="SO2_1H_DENSITY">
                    <el-input v-model="formdata.saveJson.SO2_1H_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入二氧化硫 1小时平均浓度" :disabled="!isEdit">
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="二氧化硫 1小时平均分指数" prop="SO2_14H_SUBINDEX">
                    <el-input v-model="formdata.saveJson.SO2_14H_SUBINDEX" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入二氧化硫 1小时平均分指数" :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="二氧化氮 1小时平均浓度" prop="NO2_1H_DENSITY">
                    <el-input v-model="formdata.saveJson.NO2_1H_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入二氧化氮 1小时平均浓度" :disabled="!isEdit">
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="二氧化氮 1小时平均分指数" prop="NO2_1H_SUBINDEX">
                    <el-input v-model="formdata.saveJson.NO2_1H_SUBINDEX" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入二氧化氮 1小时平均分指数" :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="颗粒物" prop="KLW_1H_DENSITY">
                    <el-input v-model="formdata.saveJson.KLW_1H_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入颗粒物" :disabled="!isEdit">
                        <template slot="append">µm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="一氧化碳 1小时平均浓度" prop="CO_1H_DENSITY">
                    <el-input v-model="formdata.saveJson.CO_1H_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入一氧化碳 1小时平均浓度" :disabled="!isEdit">
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="一氧化碳 1小时平均分指数" prop="CO_1H_SUBINDEX">
                    <el-input v-model="formdata.saveJson.CO_1H_SUBINDEX" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入一氧化碳 1小时平均分指数" :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="臭氧 8小时滑动平均浓度" prop="O3_8HPJ_DENSITY">
                    <el-input v-model="formdata.saveJson.O3_8HPJ_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入臭氧 8小时滑动平均浓度" :disabled="!isEdit">
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="臭氧 8小时滑动平均分指数" prop="O3_8HPJ_SUBINDEX">
                    <el-input v-model="formdata.saveJson.O3_8HPJ_SUBINDEX" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入臭氧 8小时滑动平均分指数" :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="颗粒物 1小时平均浓度" prop="KLW2D5_1H_DENSITY">
                    <el-input v-model="formdata.saveJson.KLW2D5_1H_DENSITY" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入颗粒物 1小时平均浓度" :disabled="!isEdit">
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="空气质量指数（AQI）" prop="AQI">
                    <el-input v-model="formdata.saveJson.AQI" :maxlength="50" onkeydown="if(event.keyCode==32) return false" placeholder="请输入空气质量指数（AQI）" :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="首要污染物" prop="MAJOR_POLLUTANT">
                    <el-input v-model="formdata.saveJson.MAJOR_POLLUTANT" :maxlength="50" placeholder="请输入首要污染物" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="空气质量指数级别" prop="AQI_CLASS">
                    <el-input v-model="formdata.saveJson.AQI_CLASS" :maxlength="50" placeholder="请输入空气质量指数级别" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="空气质量指数级别" prop="AQI_LB_LB">
                    <el-input v-model="formdata.saveJson.AQI_LB_LB" :maxlength="50" placeholder="请输入空气质量指数级别" :disabled="!isEdit"></el-input>
                </el-form-item>
                <el-form-item label="空气质量指数类别颜色" prop="AQI_LB_COLOR">
                    <el-input v-model="formdata.saveJson.AQI_LB_COLOR" :maxlength="50" placeholder="请输入空气质量指数类别颜色" :disabled="!isEdit"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from '../../../edit-button.js';
import infoMixin from '../../../info_mixin.js';
import { mapActions } from 'vuex';
export default {
    name: "servicedata_dqzlsb_form",
    components: {
    },
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: '',
            formdata: {
                saveJson: {
                    REPORT_DATE: '',
                    SO2_1H_DENSITY: 0,
                    SO2_14H_SUBINDEX: 0,
                    NO2_1H_DENSITY: 0,
                    NO2_1H_SUBINDEX: 0,
                    KLW_1H_DENSITY: 0,
                    CO_1H_DENSITY: 0,
                    CO_1H_SUBINDEX: 0,
                    O3_8HPJ_DENSITY: 0,
                    O3_8HPJ_SUBINDEX: 0,
                    KLW2D5_1H_DENSITY: 0,
                    AQI: 0,
                    MAJOR_POLLUTANT: '',
                    AQI_CLASS: '',
                    AQI_LB_LB: '',
                    AQI_LB_COLOR: '',
                },
                heritageId: '',
                itemID: '',
                userName: '',
                userId: ''
            }
        };
    },
    computed: {
    },
    watch: {
        'formdata.saveJson.cnsxlylx': function() {
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions([
        ]),
    }
};
</script>
<style lang="scss" scoped>
.basic_data_edit {
    // border:1px solid red;
}
</style>
